import "./Header.scss";

function Header({
    handleNavClick = ()=>{}
}) {
    const data = [
        {
            id: 'about',
            title: 'About'
        },
        {
            id: 'skills',
            title: 'Technical Skills'
        },
        {
            id: 'sample',
            title: 'Portfolio'
        },
    ]

    return (
        <header className='mobile'>
            <nav>
                <a
                    href="#intro"
                    className="logo"
                >
                    <img
                        className="logo-image"
                        src="./docs/assets/images/robot.svg"
                        alt="Robot Logo"
                    />
                    <h1
                        className="hover-underline"
                    >
                        SC5
                    </h1>
                </a>
                <button>
                    <svg viewBox="0 0 100 80" width="40" height="40">
                        <rect width="100" height="20"></rect>
                        <rect y="30" width="100" height="20"></rect>
                        <rect y="60" width="100" height="20"></rect>
                    </svg>
                </button>
                <ul>
                    {
                        Object.values(data).map(({id, title}) => (
                            <li>
                                <a
                                    className="hover-underline"
                                    onClick={handleNavClick}
                                    href={`#${id}`}
                                >
                                    {title}
                                </a>
                        </li>
                        ))
                    }
                </ul>
            </nav>
        </header>
    );
}

export default Header;