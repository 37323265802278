import { useState, useRef, useEffect } from "react";
import "./Section.scss";

function Section({
    children,
    className,
    isShown = true,
    id,
    data
}) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setIsIntersecting(entry.isIntersecting);
          },
          { rootMargin: "0px" }
        );
        console.log(isIntersecting);
        observer.observe(ref.current);
    
        return () => observer.disconnect();
      }, [isIntersecting]);
    
      useEffect(() => {
        if(isIntersecting){
            ref.current.classList.add('start-animation');
        }
        console.log({isIntersecting, className});
      }, [isIntersecting]);

    return (
        <section ref={ref} className={className} data-hidden={!isShown} id={id}>
            {children}
        </section>
    );
}

export default Section;