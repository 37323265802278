import data from './data.json'

function Product({product}) {
    const {
        description,
        images,
        price,
        rating,
        title,
        thumbnail
    } = product;
    return (
        <li className='product'>
            {JSON.stringify(product)}
            <img className='thumbnail' src={thumbnail} />
            <p className='title'>
                {title}
            </p>
            <p className='description'>
                {description}
            </p>
            <span className='price'>
                {price}
            </span>
            <span className='rating'>
                {rating}
            </span>
            
        </li>
    );
}

function Products() {
    console.log(data);
    const { products } = data;
    return (
        <div className="products">
            <ul className='products-list'>
                {
                    products.map(product =>
                        <Product product={product} />
                    )
                }
            </ul>
        </div>
    );
}

export default Products;