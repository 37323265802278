import cn from 'classnames';
import {data} from './data';
import Header from './components/Header/Header';
import Resume from './components/resume/Resume';
import Section from './components/Section/Section';
import Lab from './components/Lab/Lab';
import Products from './components/Products/Products';
import Gallery from './components/Gallery/Gallery';
import './App.scss';
import SkillsSection from './components/Section/SkillsSection';

function App() {
    const { info, skills } = data.resume;





    return (
        <div className="sc5">
            <Header 
                data={data.resume}
            />

            <main>
                <Section className='intro' id='intro'>
                    <div className='hero-image-container'>
                        <img 
                            className='hero-image' 
                            src='/docs/assets/images/background/bg5.png'
                        />
                    </div>
                    <div className='content'>
                        <h2>Samuel Choi</h2>
                        <p>San Francisco, CA</p>
                        <ul className='nav-cards'>
                            <li>
                                <p>
                                    I am a <span className='bold-italic'>Software Engineer</span> by trade, weekend <span className='bold-italic'>snowboarder</span> for fun, <span className='bold-italic'>CSS</span> enthusaist, and <span className='bold-italic'>OneWheeler</span> around the Bay Area.
                                </p>
                                <a role="button" href="#about">Learn More</a>
                            </li>
                            <li>
                                <p>
                                    Experienced <span className='bold-italic'>Front End UI Engineer</span> with a track record of success at companies like <span className='bold-italic'>Apple</span> and <span className='bold-italic'>Williams-Sonoma</span>.
                                </p>
                                <a role="button" href="#sample">See Portfolio</a>
                            </li>
                            <li>
                                <p>
                                    Skilled in latest <span className='bold-italic'>HTML</span>, <span className='bold-italic'>Javascript</span>, and <span className='bold-italic'>CSS</span> technologies; <span className='bold-italic'>CI/CD pipelines</span>; <span className='bold-italic'>Web Accessibility</span>
                                </p>
                                <a role="button" href="#skills">Tech</a>
                            </li>
                        </ul>
                        <p className='slide-up line-1'>
                                <span className='slide-content'>
                                    <ul className='social-media-links'>
                                        <li>
                                            <a 
                                                href="https://www.linkedin.com/in/samuel-choi-61ab7830"
                                                title="LinkedIn"
                                                target="_blank"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/sizzlersamm/"
                                                title="Instagram"
                                                target='_blank'
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.tiktok.com/@sizzler.samm"
                                                title="TikTok"
                                                target='_blank'
                                            >
                                                <svg viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"></path>
                                                    </g>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </span>
                        </p>
                    </div>
                </Section>


                <Section className='hero' id='about'>
                    <div className='about'>
                        <div>
                            <img
                                src ={`/docs/assets/images/background/headshot${Math.floor(Math.random() * 5) + 1}.png`}
                                alt='Cartoon headshot of Sam Choi'
                            />
                        </div>

                        <div>
                            <h2>
                                Samuel Choi
                            </h2>
                            <span className='bold-italic'>San Francisco, CA, USA</span>
                        </div>
                        <div>
                            <p>
                                I am a <span className='bold-italic'>Software Engineer</span> by trade, weekend <span className='bold-italic'>snowboarder</span> for fun, <span className='bold-italic'>CSS</span> enthusaist, and <span className='bold-italic'>OneWheeler</span> around the Bay Area.
                            </p>
                            <p>
                                Experienced <span className='bold-italic'>Front End UI Engineer</span> with a track record of success at companies like <span className='bold-italic'>Apple</span> and <span className='bold-italic'>Williams-Sonoma</span>.
                            </p>
                            <p>
                                Skilled in latest <span className='bold-italic'>HTML</span>, <span className='bold-italic'>Javascript</span>, and <span className='bold-italic'>CSS</span> technologies; <span className='bold-italic'>CI/CD pipelines</span>; <span className='bold-italic'>Web Accessibility</span>
                            </p>
                        </div>
                    </div>
                </Section>

                <SkillsSection />

                <Section className='portfolio' id='sample'>

                    <h2>Professional Work</h2>
                    
                    <Gallery />

                </Section>

            </main>
            <aside></aside>
            <footer>
                <div>Icons made from <a href="https://www.onlinewebfonts.com/icon">svg icons</a> is licensed by CC BY 4.0</div>
            </footer>
        </div>
    );
}

export default App;
