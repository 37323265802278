import cn from 'classnames';
import Section from "./Section";

import './SkillsSection.scss';

const skillLogos = [
    {src: "react.png", isLong: false},
    {src: "nodejs.png", isLong: true},
    {src: "typescript.png", isLong: false},
    {src: "mysql.png", isLong: true},
    {src: "npm.png", isLong: true},
    {src: "jenkins.png", isLong: false},
    {src: "cypress.svg", isLong: true},
    {src: "scss.png", isLong: false},
    {src: "git.png", isLong: false},
    {src: "json.png", isLong: false},
    {src: "sass.png", isLong: true},
    {src: "css3.png", isLong: false},
    {src: "vue.png", isLong: false},
    {src: "html.png", isLong: false},
    {src: "js.png", isLong: false},
    {src: "ruby.png", isLong: false},
    {src: "jasmine.svg", isLong: true},
    {src: "es6.png", isLong: false},
    {src: "canjs.png", isLong: true},
    {src: "jest.png", isLong: false},
    {src: "php.png", isLong: false},
    {src: "mongodb.png",  isLong: true},       
    {src: "svn.png", isLong: false},
    {src: "mustache.png", isLong: true},
    {src: "xml.png", isLong: false},
    {src: "freemarker.jpeg", isLong: true},
    {src: "capybara.png", isLong: true},
    {src: "splunk.png", isLong: false},
    {src: "jquery.png", isLong: false},
];

function SkillsSection(){

    
    return(
        <Section className="skills" id='skills'>
            <div className='content'>
                <img className='hero-image' src='/docs/assets/images/background/bg3.png' />

                <h2>
                    Tools I use
                </h2>
                <ul className=''>
                    {
                        skillLogos.map(logo => (
                            <li
                                className={cn('skill-logo', {
                                    'skill-logo-long': logo.isLong
                                })}

                            >
                                <img
                                    src={`/docs/assets/images/logos/${logo.src}`}
                                    alt='Robot Logo for SC5'
                                />
                        </li>

                        ))
                    }
                </ul>
            </div>            
        </Section>
    );
}

export default SkillsSection;