export const data = {
    'sample-work': {
        text: `Welcome to my creative space! I'm Sam Choi, a Front End and Full Stack Web Developer
        fueled by an unwavering passion for technology.
        <br />
        With a background in Software and E-Commerce,
        I specialize in Javascript, HTML, and CSS, combining artistry with functionality.
        <br />
        This portfolio is a snapshot of my journey, showcasing projects that encapsulate my commitment to 
        detail and clean code.
        <br />From crafting compelling narratives to solving intricate problems, 
        each endeavor reflects my dedication to pushing boundaries.
        <br />Join me on this visual exploration, where ideas transform into extraordinary realities. Let's connect and collaborate to bring your
         visions to life!`
    },
    resume: {
        info: {
            id:'about',
            title: 'About',
            name: 'Sam Choi',
            jobTitle: 'Front End UI Engineer',
            location: 'San Francisco, CA',
            linkedIn: ''    
        },
        education: {
            id:'education',
            title: 'Education',
            degree: 'B.S. Computer Science',
            school: 'University of California, Santa Barbara',
            year: '2008'
        },
        skills: {
            id:'skills',
            title: 'Skills',
            skills: [
                {
                    title: 'JavaScript',
                    items: [
                        { 
                            name: 'React.JS', 
                            logo: ''
                        },
                        {
                            name: 'Vanilla ES6',
                            logo: ''
                        },
                        {
                            name: 'NodeJS'
                        },
                        {
                            name: 'NPM'
                        }, 
                        {
                            name: 'Vue.js',
                        },
                        {   name: 'CanJS',
                        },  
                        {   name: 'jQuery'},
                    ]
                },
                {
                    title: 'HTML',
                    items: [
                        {name: 'JSX'},
                        {name: 'Freemarker'},
                        {name: 'Mustache'}, 
                        {name: 'HAML'},
                    ]
                },
                {
                    title: 'CSS',
                    items: [
                        {
                            name: 'Tailwind'
                        },
                        {
                            name: 'Sass/Less',
                        },
                        {
                            name: 'CSS3'
                        }
                    ]
                },
                {
                    title: 'Version Control',
                    items: [
                        {name: 'Git'}, 
                        {name: 'Subversion'}
                    ]
                },
                {
                    title: 'Server Side REST API Development',
                    items: [
                        {name: 'JSON'},
                        {name: 'XML'},
                        {name: 'Ruby'}, 
                        {name: 'PHP'},
                    ]
                },
                {
                    title: 'Testing',
                    items: [
                        {name: 'Jest'},
                        {name: 'Jasmine'},
                        {name: 'Cypress'},
                        {name: 'Capybara'},
                    ]
                },
                {
                    title: 'scripting/automation/logging/ dev ops',
                    items: [
                        {name: 'BASH'},
                        {name: 'SHELL'},
                        {name: 'Jenkins'},
                        {name: 'Node'},
                        {name: 'Splunk'},
                    ]
                },
                {
                    title: 'Project Management Methodologies',
                    items: [
                        {name: 'Agile'},
                        {name: 'Scrum'},
                        {name: 'Test Driven Development'},
                        {name: 'Waterfall'},
                        {name: 'Jira'},
                        {name: 'Kanban'},
                    ]
                },
                {
                    title: 'Databases',
                    items: [
                        {name: 'mySQL'},
                        {name: 'mongoDB'}
                    ]
                }
            ]
        },
        experience: {
            id: 'experience',
            title: 'Experience',
            roles: [
            {
                company: 'Apple Online Store',
                location: 'Sunnyvale, CA',
                dates: 'March 2020 - September 2023',
                title: 'Front End UI Engineer [Red Oak Technologies Contract]',
                description: [
                    `Owned end-to-end UI development for ML powered product recommendations (Shop By Availability/SBA) on Apple Watch, iPhone, and iPad lines. The project required consuming ML data to show users, but also sending back user interaction information back via API to the ML models. SBA required coordination with a globally distributed team to meet the requirements of business and engineering teams.`,
                    `Implemented redesign of Apple Watch product pages from wireframes, in coordination with a larger cross functional team. Project consisted of 5-7 front end engineers to iteratively build and integrate features.`,
                    `Migrated Apple flagship product pages from previous tech stack to React, including Apple Watch, iPad, and Mac. Contributions led to 30% in page weight reduction and 25% improvements in browser performance.`,
                    `Created testing and build scripts in NodeJS for CI/CD.`,
                    `Contributed to UI features company wide component libraries, including Date Pickers, Alert Banners, and Navigation elements.`,
                    `Took ownership of Apple Watch Design Studio and Apple Watch Bands pages, including implementing new UI requests, accessibility compliance, and production support for bug fixes.`,
                    `Maintained code repositories by: performing code reviews, updating README and other documentation, ensuring local demos are functional and current, publishing appropriate package versions via NPM.`,
                    `Monitor and troubleshoot CI/CD build processes to non-prod environments with Splunk and Jenkins.`,
                    `Audited and fixed Web Accessibility issues across site domains.`
                ]
            },
            {
                company:'Williams-Sonoma, Inc',
                location: 'San Francisco, CA',
                dates: 'September 2019 - March 2020',
                title: 'Front End Engineer [TekSystems Contract]',
                description: [
                    `Implemented new public facing features to enhance the customer experience for Gift Registry and Product Information Pages (PIP) to be scaled across 6 e-commerce domains`,
                    `Migrated legacy codebase from BackboneJS to Vue.js/NodeJS stack.`,
                    `Improved code quality and test coverage standards using Jest testing framework. Increased code coverage by 30%.`, `Updated legacy UI components to meet modern Web Accessibility Standards.`,
                    `Presented UI demos to executive level leadership.`
                ]
            },
            {
                company:'Apple Online Store',
                location: 'Sunnyvale, CA',
                dates: 'March 2015 - September 2019',
                title: 'Front End UI Engineer [TekSystems Contract]',
                description: [
                    `Developed front-end applications and new UX features on eCommerce web platform and delivered production ready code for Apple iPad, Apple Watch, Apple Accessories, Checkout, Refurbished Products, and iPhone Trade-in`, 
                    `Migrated legacy CSS to SCSS`,
                    `Modernized legacy UI elements from CanJS framework to internal ES6 framework`,
                    `Prepared release versions of front-end builds using NPM and NodeJS`,
                    `Improved code quality and test coverage standards with Jasmine tests.`,
                    `Reviewed pull requests to shared group code repositories`
                ]
            },
            {
                company:'One Kings Lane',
                location: 'San Francisco, CA',
                dates: 'July 2011 - September 2014',
                title: 'Software Engineer',
                description: [
                    `Migrated legacy PHP e-commerce codebase to Ruby on Rails`,
                    `Created consumer facing features: Customer Referral Programs, Loyalty Shipping Programs, Promo/Discount Code redemption`,
                    `Maintained Shopping Cart and Checkout API for iOS app`,
                    `Developed internal PHP applications for failed payment reauthorization and automated sales tax updates across business platforms.`
                ]
            },
            {
                company:'Exygy Web Apps',
                location: 'San Francisco, CA',
                dates: 'February 2009 - May 2011',
                title: 'Web Developer',
                description: [
                    `Created custom WordPress solutions for a diverse client base.`,
                    `Designed and developed new web pages for clients using Codeigniter and CakePHP frameworks.`
                ]
            }
        ]}
    }
}