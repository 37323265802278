import cn from 'classnames';
import {useRef, useState} from 'react';
import "./Gallery.scss";

const myData = [
    {
        id: 'slide-1',
        imgSrc: '/docs/assets/images/watch-pdp.png',
        imgAlt: 'Screenshot of Apple Watch Product Detail Page',
        title: 'Apple Watch Product Detail Page',
        description: 'Collaborated with a globally distributed team to redesign the Apple Watch buying experience.',
        link: 'https://www.apple.com/shop/buy-watch/apple-watch?configured=true&option.watch_cases=MR9R3LL/A&option.watch_bands=MT5E3AM/A&product=Z0YQ&step=select'
    },
    {
        imgSrc: '/docs/assets/images/design-studio.png',
        imgAlt: 'Screenshot of Apple Watch Design Studio',
        title: 'Apple Watch Design Studio',
        description: 'Led React migration initiatives of Apple Watch Design Studio',
        link: 'https://www.apple.com/shop/studio'
    },    
    {
        imgSrc: '/docs/assets/images/screencapture-apple-watch-bands.png',
        imgAlt: 'Screenshot of Apple Watch Bands Page',
        title: 'Apple Watch Bands Collection Page',
        description: 'Led React migration of Apple Watch Bands',
        link: 'https://www.apple.com/shop/watch/bands'
    },
    {
        imgSrc: '/docs/assets/images/screencapture-westelm-sofa.png',
        imgAlt: 'Screenshot of West Elm Sofa Page',
        title: 'West Elm Fabric Selector',
        description: 'Feature Enhancements to Fabric Selector for couches and sofas',
        link: 'https://www.westelm.com/products/haven-2-piece-terminal-chaise-sectional-h3422/?pkey=csectionals'
    },
    {
        imgSrc: '/docs/assets/images/sba.png',
        imgAlt: 'Screenshot of SBA on iPad',
        title: 'Apple Shop By Availability',
        description: 'Led front-end development for the Shop By Availability feature for iPad, iPhone, and Apple Watch, which allows customers to view real-time inventory based on time and geo-location.'
    },
    {
        imgSrc: '/docs/assets/images/screencapture-williams-sonoma-registry.png',
        imgAlt: 'Screenshot of Williams Sonoma Gift Registry',
        title: 'Williams Sonoma, Inc. Cross Brand Gift Registry',
        description: 'Major enhancement to allow customers to add products from any WS, Inc. family store into a unified Gift Registry.'
    },
]


function Card({
    id='',
    clickHandler=()=>{},
    link,
    imgSrc='/docs/assets/images/watch-pdp.png',
    imgAlt='Screenshot of Apple Watch Product Detail Page',
    title='Apple Watch Product Detail Page',
    description='Helped the Apple Online Store in 2023 with the redesigning the Apple Watch buying experience.'
}) {
    const onClick = e => {
        clickHandler({
            title,
            description,
            imgSrc,
            link
        });
    }
    return (
        <li className="card" id={id}>
            <div className='card-info'>
                <img className='fade slide-down' 
                src={imgSrc}
                alt={imgAlt}
                />
                <p className="description">
                    <span className="title">{title}</span>
                </p>

            </div>
            <button onClick={onClick} popovertarget="my-popover" popovertargetaction='show'>See more</button>                
        </li>
    )
}

function Modal({
    imgSrc,
    title,
    description,
    link
}) {
    return (
        <div className='modal' popover='true' id="my-popover">
            <button className='close' popovertarget='my-popover' popovertargetaction='hide'>
            <svg width="40" height="40" viewbox="0 0 40 40"><path d="M 10,10 L 30,30 M 30,10 L 10,30" stroke="black" stroke-width="4" /></svg>
            </button>
            <h3>
                {title}
            </h3>
            <img src={imgSrc} />
            <p>
                {description}
                <a target='_blank' href={link}>Visit</a>
            </p>
        </div>
    );
}
function Gallery() {
    const galleryRef = useRef();

    const [currentCard, setCurrentCard] = useState({});
    return (
        <div className='gallery-wrapper'>
            <Modal {...currentCard} />
            <ul className={cn('gallery', {
                grid: true,
                carousel: false
            })}
                ref={galleryRef}
            >
                {
                    myData.map(card => (
                        <Card
                            {...card}
                            clickHandler={setCurrentCard}
                        />
                    ))
                }
            </ul>
        </div>

        
        );
    }
    
    export default Gallery;